<template>
  <div>

  <div v-if="mode==='ul'" >
    <ul>
      <li v-for="prest in (showAll?this.prestazioni:this.firstPrestazioni)" v-bind:key="prest"> <h3>{{ prest }}</h3></li>
    </ul>
    <ul v-if="this.prestazioni.length!=this.firstPrestazioni.length"  style="font-size: small">
      <b-link style="text-decoration: underline" @click="toggleShowAll()">{{$t(showAll?"mdl.unshowall":"mdl.showall")}}</b-link>
    </ul>
  </div><div v-if="mode==='ul-sm'" >
    <ul>
      <li v-for="prest in (showAll?this.prestazioni:this.firstPrestazioni)" v-bind:key="prest"> <strong>{{ prest }}</strong></li>
    </ul>
    <ul v-if="this.prestazioni.length!=this.firstPrestazioni.length"  style="font-size: small">
      <b-link style="text-decoration: underline" @click="toggleShowAll()">{{$t(showAll?"mdl.unshowall":"mdl.showall")}}</b-link>
    </ul>
  </div>
  <div v-if="mode==='p'" >

      <p v-for="prest in (showAll?this.prestazioni:this.firstPrestazioni)" v-bind:key="prest">{{ prest }}</p>

    <p v-if="this.prestazioni.length!=this.firstPrestazioni.length" style="font-size: small">
      <b-link style="text-decoration: underline" @click="toggleShowAll()">{{$t(showAll?"mdl.unshowall":"mdl.showall")}}</b-link>
    </p>
  </div>
    <div v-if="mode==='p-strong'" >

      <p v-for="prest in (showAll?this.prestazioni:this.firstPrestazioni)" v-bind:key="prest"><strong>{{ prest }}</strong></p>

      <p v-if="this.prestazioni.length!=this.firstPrestazioni.length" style="font-size: small">
        <b-link style="text-decoration: underline" @click="toggleShowAll()">{{$t(showAll?"mdl.unshowall":"mdl.showall")}}</b-link>
      </p>
    </div>
    <div v-if="mode==='div'" >

      <div v-for="prest in (showAll?this.prestazioni:this.firstPrestazioni)" v-bind:key="prest"><strong>{{ prest }}</strong></div>

      <div v-if="this.prestazioni.length!=this.firstPrestazioni.length" style="font-size: small">
        <b-link style="text-decoration: underline" @click="toggleShowAll()">{{$t(showAll?"mdl.unshowall":"mdl.showall")}}</b-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppPrestazioni",
  props: {
    items: {
      default: [],
    },
    mode:{
      default:"ul"
    }
  },
  data() {
    return {
      showAll:false,
      maxRow:1
    };
  },
  computed:{
    prestazioni(){
      if (Array.isArray(this.items))
        return this.items;
      return this.items.split('\n');
    },
    firstPrestazioni(){

      let limit=this.maxRow;
     /* if (this.prestazioni.length==limit+1)
      {
        limit++;
      }*/
      let res=[];
      for (var i=0;i<limit;i++)
      {
        if(this.prestazioni.length>i)
          res.push(this.prestazioni[i]);
      }

      return res;
    }
  },

  methods: {
    toggleShowAll(){
      this.showAll=!this.showAll;
    },
  },
};
</script>
