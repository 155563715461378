<template>
  <div class="col-12 ">
    <p class="o-modal__body--title mt-5">
      <strong>{{$t('mdl.filtro.titolo')}}</strong>
    </p>

    <div class="col-12 mt-5">
      <b-form-group
          id="data-inzio"
          :label="this.$t('mdl.filtro.inizio')"
          label-for="data-inizio-input"
          class="m-input o-mo"
      >
        <b-form-datepicker
            id="data-inizio-input"
            v-model="data_inizio"
            type="date"
            :placeholder="this.$t('mdl.filtro.inizio-place')"
            required
            :min="calcMinDate()"

        ></b-form-datepicker>
      </b-form-group>

      <b-form-group  id="tipo" :label="this.$t('mdl.filtro.tipo')" label-for="tipo-select"
                     class="m-input">
        <b-form-select id="tipo-select" v-model="interno" :options="optionsInternoEsterno">
        </b-form-select>
      </b-form-group>

      <div class="o-registration-collapse__error" v-if="this.dialogErrorStr!=null">

        <p>{{$t('mdl.filtro.errors.'+this.dialogErrorStr)}}</p>

      </div>

      <div class=" col-12 d-block d-md-none ml-sm-0 ml-lg-1 mr-sm-0 mr-lg-1">

        <b-button variant="primary" size="md" class="text-uppercase col-12 mt-3 col-lg-5" @click="startRequest()"
        >{{ $t('mdl.filtro.prosegui') }} </b-button>
        <b-button variant="outline-primary" size="md" class="text-uppercase  mt-3  col-12 col-lg-5 offset-lg-2 " @click="annullaRequest()"
        >{{ $t('mdl.filtro.indietro') }} </b-button>
      </div>
      <div class=" col-12 d-none d-md-block ml-1  mr-1">
        <b-button variant="outline-primary" size="md" class="text-uppercase mr-3 mt-3  col-5 col-md-5  " @click="annullaRequest()"
        >{{ $t('mdl.filtro.indietro') }} </b-button>
        <b-button variant="primary" size="md" class="text-uppercase ml-3 col-5 mt-3 col-lg-5" @click="startRequest()"
        >{{ $t('mdl.filtro.prosegui') }} </b-button>

      </div>
    </div>
  </div>
</template>

<script>

import apiAxios from "@/libs/apiAxios";

export default {
  name: "DlgMdlFiltro",
  props: {
    newquery:{
      default:true
    },
    plan:{
      default:null
    },
    sedi:{
      default:null
    }

  },
  data() {
    return {
        data_inizio:null,
        interno:null,
        dialogErrorStr:null,
        optionsInternoEsterno: [
          {value:null,text: this.$t("mdl.filtro.tipologia.selezionare")},
      { value: "I", text: this.$t("mdl.filtro.tipologia.interno") },
      { value: "E", text: this.$t("mdl.filtro.tipologia.esterno") },
    ],
    };
  },


  computed:{
    sediCalc(){
      if (this.sedi==null)
      {
       return [];
      }
      return this.sedi;
    }
  },
  methods: {
    getPrestazione(plan){
      let res="";
      for(var i=0;i<plan.prestazioni.length;i++)
      {
        if (i>0)
          res+="\n";
        res+=plan.prestazioni[i];
      }
      return res;
    },
    calcMinDate()
    {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      let tomorrow=today;
      tomorrow.setDate(today.getDate() + 1);

      return tomorrow;

    },
    failReserveDialog(err)
    {
      this.dialogErrorStr=err;

    },
    successReserveDialog(query,result)
    {


      const data={
        query:query,
        data:result
      }


      sessionStorage.setItem('mdlrequest',btoa(JSON.stringify(data)));
      this.$emit('ondata');

    },
    annullaRequest() {
      this.$emit("onhide");
    },
    startRequest()
    {

      this.dialogErrorStr=null;
      if (this.data_inizio==null)
      {
        this.dialogErrorStr="ERR_INSERT_DATA";
        return;
      }
      if (this.interno==null)
      {
        this.dialogErrorStr="ERR_INSERT_INTERNO";
        return;
      }
      sessionStorage.removeItem('mdlrequest');
      const postData={
        episodio:this.plan.episodio.guid,
        data_inizio:this.data_inizio,
        tipo:this.interno,
        sedi:this.sediCalc,
        prestazione: this.getPrestazione(this.plan)
      }
      let _this=this;
      apiAxios.post("mdl/disponibilita",postData)
          .then(result=>{
            const data=result.data.MdlDispoResult;
            if (data.success)
            {
              _this.successReserveDialog(postData,data.data)
            }
            else {
              _this.failReserveDialog(data.error)
            }
          })
          .catch(error=>{
            _this.failReserveDialog(error);
          })
    },
  },
};
</script>
